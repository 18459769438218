import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { CarouselModule } from '@jfw-library/ecommerce/common-components/carousel';
import {
  EcommerceCoreModule,
  MaintenanceComponent,
} from '@jfw-library/ecommerce/core';
import { environment } from '../../environments/environment';
import { EmailPasswordFormComponent } from './components/authentication/login/email-password-form/email-password-form.component';
import { ProdLoginComponent } from './components/authentication/login/prod-login/prod-login.component';
import { SignInV2Component } from './components/authentication/login/sign-in-v2/sign-in-v2.component';
import { LogoutProxyComponent } from './components/authentication/logout/logout-proxy/logout-proxy.component';
import { LogoutComponent } from './components/authentication/logout/logout/logout.component';
import { CustomizeLookFiltersComponent } from './components/filters/customize-look/customize-look.component';
import { EnsembleFiltersComponent } from './components/filters/ensemble/ensemble.component';
import { StyleFiltersComponent } from './components/filters/style/style.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderDesktopComponent } from './components/header/desktop/desktop.component';
import { SearchDesktopComponent } from './components/header/desktop/search/search.component';
import { HeaderComponent } from './components/header/header.component';
import { HeaderMobileComponent } from './components/header/mobile/mobile.component';
import { SearchMobileComponent } from './components/header/mobile/search/search.component';
import { HomePageCopyComponent } from './components/home-page-copy/home-page-copy.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { EnsembleListingPageComponent } from './components/listing-pages/ensemble/ensemble.component';
import { SearchListingPageComponent } from './components/listing-pages/search/search.component';
import { StyleListingPageComponent } from './components/listing-pages/style/style.component';
import { HeaderSidenavComponent } from './components/sidenavs/header/header.component';
import { PlpFilterSidenavComponent } from './components/sidenavs/plp-filter/plp-filter.component';
import { EcomEventService } from './services/ecom-event/ecom-event.service';
import { MarkdownService } from './services/markdown/markdown.service';
import { PaymentApiService } from './services/payment/payment-api.service';
import { PaymentService } from './services/payment/payment.service';
import { PreviousRouteService } from './services/previous-route/previous-route.service';
import { UPSApiService } from './services/ups-api/ups-api.service';
import { UserEcomSettingsApi } from './services/user-ecom-settings/user-ecom-settings-api.service';
import { UserEcomSettingsService } from './services/user-ecom-settings/user-ecom-settings.service';

@NgModule({
  declarations: [
    CustomizeLookFiltersComponent,
    EmailPasswordFormComponent,
    EnsembleFiltersComponent,
    EnsembleListingPageComponent,
    FooterComponent,
    HeaderComponent,
    HeaderDesktopComponent,
    HeaderMobileComponent,
    HeaderSidenavComponent,
    HomePageComponent,
    HomePageCopyComponent,
    LogoutComponent,
    LogoutProxyComponent,
    PlpFilterSidenavComponent,
    ProdLoginComponent,
    SearchDesktopComponent,
    SearchListingPageComponent,
    SearchMobileComponent,
    SignInV2Component,
    StyleFiltersComponent,
    StyleListingPageComponent,
  ],

  imports: [
    CarouselModule,
    CommonModule,
    EcommerceCoreModule.forRoot(environment),
    FormsModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    RouterModule,
  ],

  // All services and guards need to be added to the providers array.
  providers: [
    EcomEventService,
    MarkdownService,
    PaymentApiService,
    PaymentService,
    PreviousRouteService,
    UPSApiService,
    UserEcomSettingsApi,
    UserEcomSettingsService,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],

  exports: [
    // for use in app.component.html
    CustomizeLookFiltersComponent,
    FooterComponent,
    HeaderComponent,
    HeaderSidenavComponent,
    MaintenanceComponent,
    PlpFilterSidenavComponent,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only.',
      );
    }
  }
}
