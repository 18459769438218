import { Routes } from '@angular/router';
import { AnonTokenGuard } from '@jfw-library/ecommerce/core';
import { ProdLoginComponent } from './core/components/authentication/login/prod-login/prod-login.component';
import { SignInV2Component } from './core/components/authentication/login/sign-in-v2/sign-in-v2.component';
import { LogoutProxyComponent } from './core/components/authentication/logout/logout-proxy/logout-proxy.component';
import { LogoutComponent } from './core/components/authentication/logout/logout/logout.component';
import { SearchMobileComponent } from './core/components/header/mobile/search/search.component';
import { HomePageCopyComponent } from './core/components/home-page-copy/home-page-copy.component';
import { HomePageComponent } from './core/components/home-page/home-page.component';
import { EnsembleListingPageComponent } from './core/components/listing-pages/ensemble/ensemble.component';
import { SearchListingPageComponent } from './core/components/listing-pages/search/search.component';
import { StyleListingPageComponent } from './core/components/listing-pages/style/style.component';
import { verifyLoginUserGuard } from './core/guards/verify-user-login/verify-user-login.guard';

export const routes: Routes = [
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // CORE MODULE
  /////////////////////////////////////////////////////////////////////////////////////////////////
  { path: 'logout', component: LogoutComponent },
  { path: 'logout-proxy', component: LogoutProxyComponent },
  { path: 'prod', component: ProdLoginComponent },
  { path: 'V2login', component: SignInV2Component },
  {
    path: 'ensembles',
    component: EnsembleListingPageComponent,
    canActivate: [AnonTokenGuard],
  },
  {
    path: 'ensembles/:category',
    component: EnsembleListingPageComponent,
    canActivate: [AnonTokenGuard],
    data: {
      metaTitle: 'Ensembles Test',
      metaDescription: 'Ensembles Listings',
      metaKeywords: 'ensembles, tuxedos, formal wear, JFW',
    },
  },
  {
    path: 'ensembles/:category/:colorFamily',
    component: EnsembleListingPageComponent,
    canActivate: [AnonTokenGuard],
    data: {
      metaTitle: 'Ensembles Test',
      metaDescription: 'Ensembles Listings',
      metaKeywords: 'ensembles, tuxedos, formal wear, JFW',
    },
  },
  {
    path: 'ensembles/:category/:key1/:val1',
    component: EnsembleListingPageComponent,
    canActivate: [AnonTokenGuard],
    data: {
      metaTitle: 'Ensembles Test',
      metaDescription: 'Ensembles Listings',
      metaKeywords: 'ensembles, tuxedos, formal wear, JFW',
    },
  },
  {
    path: 'ensembles/:category/:key1/:val1/:key2/:val2',
    component: EnsembleListingPageComponent,
    canActivate: [AnonTokenGuard],
    data: {
      metaTitle: 'Ensembles Test',
      metaDescription: 'Ensembles Listings',
      metaKeywords: 'ensembles, tuxedos, formal wear, JFW',
    },
  },
  {
    path: 'home',
    component: HomePageComponent,
    canActivate: [AnonTokenGuard],
    data: {
      metaTitle: 'Home',
      metaDescription: 'Home page description',
      metaKeywords: 'tuxedos, formal wear, JFW, Jims',
    },
  },
  {
    path: 'home-copy',
    component: HomePageCopyComponent,
    canActivate: [AnonTokenGuard],
    data: {
      metaTitle: 'Home-copy',
      metaDescription: 'Home page description',
      metaKeywords: 'tuxedos, formal wear, JFW, Jims',
    },
  },
  {
    path: 'products',
    component: SearchListingPageComponent,
    canActivate: [AnonTokenGuard],
  },
  {
    path: 'search',
    component: SearchMobileComponent,
    canActivate: [AnonTokenGuard],
  },
  {
    path: 'styles/:category',
    component: StyleListingPageComponent,
    canActivate: [AnonTokenGuard],
  },
  {
    path: 'styles',
    component: StyleListingPageComponent,
    canActivate: [AnonTokenGuard],
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // LAZY-LOADED COMPONENTS
  /////////////////////////////////////////////////////////////////////////////////////////////////
  {
    path: 'accessibility',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/content-pages/accessibility/accessibility.component'
      ).then((c) => c.AccessibilityComponent),
    data: {
      metaTitle: 'Accessibility Statement',
      metaDescription:
        'We are committed to providing an inclusive experience for all users. Read our accessibility statement to learn how we are taking steps to ensure equal access for everyone.',
    },
  },
  {
    path: 'auth-email-action',
    loadComponent: () =>
      import(
        '@jfw-library/ecommerce/common-components/auth-email-actions'
      ).then((c) => c.AuthEmailActionContainerComponent),
    canActivate: [AnonTokenGuard],
  },
  {
    path: 'become-a-jfw-retailer',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/content-pages/become-a-jfw-retailer/become-a-jfw-retailer.component'
      ).then((c) => c.BecomeAJfwRetailerComponent),
  },
  {
    path: 'become-a-referral-partner',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/content-pages/become-a-referral-partner/become-a-referral-partner.component'
      ).then((c) => c.BecomeAReferralPartnerComponent),
  },
  {
    path: 'big-and-tall',
    //canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/content-pages/big-and-tall/big-and-tall.component'
      ).then((c) => c.BigAndTallComponent),
    data: {
      metaTitle: 'Big and Tall Suit and Tuxedo Sizes',
      metaDescription:
        'Find the perfect fit with our extensive collection of big and tall clothing sizes. Explore stylish and comfortable options for every occasion. Shop now and embrace your unique style!',
    },
  },
  {
    path: 'careers',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/content-pages/careers/careers.component'
      ).then((c) => c.CareersComponent),
  },
  {
    path: 'charity-work',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/content-pages/charity-work/charity-work.component'
      ).then((c) => c.CharityWorkComponent),
    data: {
      metaTitle: 'Taking Pride In Our Charity Work',
      metaDescription:
        'Discover how JFW makes a positive impact through charitable initiatives. Learn about our philanthropic efforts and the causes we support. Join us in making a difference.',
    },
  },
  // disabled for now
  // {
  //   path: 'color-guide',
  //   canActivate: [AnonTokenGuard],
  //   loadComponent: () =>
  //     import(
  //       './standalone/components/content-pages/color-guide/color-guide.component'
  //     ).then((c) => c.ColorGuideComponent),
  // },
  {
    path: 'contact-us',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/content-pages/contact-us/contact-us.component'
      ).then((c) => c.ContactUsComponent),
    data: {
      metaTitle: 'Contact Us',
      metaDescription:
        "Connect with us easily through our contact page. Reach out for inquiries, feedback, or assistance. We're here to help. Get in touch with us today!",
    },
  },
  {
    path: 'contests',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/content-pages/contests/contests.component'
      ).then((c) => c.ContestsComponent),
  },
  {
    path: 'debug/local-storage',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/debug/local-storage/local-storage.component'
      ).then((c) => c.LocalStorageComponent),
  },
  // {
  //   path: 'debug/test-modals',
  //   canActivate: [AnonTokenGuard],
  //   loadComponent: () =>
  //     import(
  //       './standalone/components/debug/test-modals/test-modals.component'
  //     ).then((c) => c.TestModalsComponent),
  // },
  {
    path: 'email-us',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/content-pages/email-us/email-us.component'
      ).then((c) => c.EmailUsComponent),
    data: {
      metaTitle: 'Email Us',
      metaDescription: '',
    },
  },
  {
    path: 'faq',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import('./standalone/components/content-pages/faqs/faqs.component').then(
        (c) => c.FaqsComponent,
      ),
    data: {
      metaTitle: 'Frequently Asked Questions',
      metaDescription:
        'Find answers to common questions in our comprehensive FAQ page. Get the information you need about our products, services, and policies. Explore now!',
    },
  },
  {
    path: 'free-swatches',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/content-pages/free-swatches/free-swatches.component'
      ).then((c) => c.FreeSwatchesComponent),
    data: {
      metaTitle: 'Try Before You Buy With Free Swatches',
      metaDescription:
        'Experience our products firsthand with free swatches. Order swatches to touch and feel the quality before making your decision. Get yours now and make an informed choice!',
    },
  },
  {
    path: 'getting-measured',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/content-pages/getting-measured/getting-measured.component'
      ).then((c) => c.GettingMeasuredComponent),
    data: {
      metaTitle: `Getting Measured`,
      metaDescription:
        'Get the perfect fit for your suit with professional measurements. Visit our webpage to learn how to measure accurately for a tailored look. Get measured now!',
    },
  },
  {
    path: 'invite/:eventId/:memberEmail',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/invite-landing-page/invite-landing-page.component'
      ).then((c) => c.InviteLandingPageComponent),
  },
  {
    path: 'kids',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import('./standalone/components/content-pages/kids/kids.component').then(
        (c) => c.KidsComponent,
      ),
    data: {
      metaTitle: 'Trendy Youth Sizes',
      metaDescription:
        'Dress your young ones in style with our trendy youth clothing sizes. From casual to formal, explore fashionable options for kids and teens. Shop now for the perfect fit!',
    },
  },
  {
    path: 'measurements-return',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/measurements-return/measurements-return.component'
      ).then((c) => c.MeasurementsReturnComponent),
  },
  {
    path: 'our-process',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/content-pages/our-process/our-process.component'
      ).then((c) => c.OurProcessComponent),
    data: {
      metaTitle: 'Our Process From Start To Finish',
      metaDescription:
        'Discover our meticulous process from start to finish. Learn how we deliver exceptional quality and service through our step-by-step approach. Explore our process now!',
    },
  },
  {
    path: 'out-of-town-groomsmen',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/content-pages/out-of-town-groomsmen/out-of-town-groomsmen.component'
      ).then((c) => c.OutOfTownGroomsmenComponent),
    data: {
      metaTitle: 'An Easy Process For Out of Town Groomsmen',
      metaDescription:
        'Guide for out-of-town groomsmen: Navigate your role with ease. From coordination advice to getting measured, make the most of your groomsman experience. Read more!',
    },
  },
  {
    path: 'privacy',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/content-pages/privacy/privacy.component'
      ).then((c) => c.PrivacyComponent),
    data: {
      metaTitle: 'Your Privacy Is Important',
      metaDescription:
        'Our privacy policy ensures your personal information is protected. Learn how we handle data, maintain security, and respect your privacy. Read our policy now.',
    },
  },
  // disabled for now
  // {
  //   path: 'real-people',
  //   canActivate: [AnonTokenGuard],
  //   loadComponent: () =>
  //     import(
  //       './standalone/components/content-pages/real-people/real-people.component'
  //     ).then((c) => c.RealPeopleComponent),
  // },
  {
    path: 'returns-and-exchanges',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/content-pages/returns-and-exchanges/returns-and-exchanges.component'
      ).then((c) => c.ReturnsAndExchangesComponent),
    data: {
      metaTitle: 'Easy Process for Returns and Exchanges',
      metaDescription:
        'Easy and hassle-free returns and exchanges. Learn about our flexible policies and procedures. Customer satisfaction is our top priority. Start your return or exchange process now!',
    },
  },
  {
    path: 'request-return-label',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/content-pages/request-return-label/request-return-label.component'
      ).then((c) => c.RequestReturnLabelComponent),
  },
  {
    path: 'sitemap',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/content-pages/site-map/site-map.component'
      ).then((c) => c.SiteMapComponent),
  },
  {
    path: 'special-offers',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/content-pages/special-offers/special-offers.component'
      ).then((c) => c.SpecialOffersComponent),
    data: {
      metaTitle: 'Take Advantage Of Our Special Offers',
      metaDescription:
        "Explore our exclusive special offers and promotions. Save on your favorite products and services. Don't miss out on incredible deals. Discover our special offers today!",
    },
  },
  {
    path: 'store-detail/:storeId',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/store/store-detail-page/store-detail-page.component'
      ).then((c) => c.StoreDetailPageComponent),
  },
  {
    path: 'store-locator',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/store/store-locator-page/store-locator-page.component'
      ).then((c) => c.StoreLocatorPageComponent),
  },
  {
    path: 'style/:styleCode',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/style-detail-page/style-detail-page.component'
      ).then((c) => c.StyleDetailPageComponent),
  },
  {
    path: 'terms-of-use',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/content-pages/terms-of-use/terms-of-use.component'
      ).then((c) => c.TermsOfUseComponent),
    data: {
      metaTitle: 'Terms of Use',
      metaDescription:
        'Understand the terms of use governing your interactions with our website. Explore our policies and guidelines for a transparent user experience. Read our terms now.',
    },
  },
  {
    path: 'the-jfw-story',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/content-pages/the-jfw-story/the-jfw-story.component'
      ).then((c) => c.TheJfwStoryComponent),
    data: {
      metaTitle: 'Learn About The JFW Story',
      metaDescription:
        'Discover the inspiring JFW story. From humble beginnings to a national brand, learn about our journey, values, and commitment to excellence. Explore our story now!',
    },
  },
  {
    path: 'ways-to-rent-or-buy',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/content-pages/ways-to-rent-or-buy/ways-to-rent-or-buy.component'
      ).then((c) => c.WaysToRentOrBuyComponent),
    data: {
      metaTitle: 'Find Hassle-Free Ways to Rent or Buy',
      metaDescription:
        'Discover hassle-free options to rent or buy products. Explore our wide selection and flexible choices for convenient shopping. Find the perfect solution today!',
    },
  },
  {
    path: 'why-choose-jfw',
    canActivate: [AnonTokenGuard],
    loadComponent: () =>
      import(
        './standalone/components/content-pages/why-choose-jfw/why-choose-jfw.component'
      ).then((c) => c.WhyChooseJfwComponent),
    data: {
      metaTitle: 'Why Choose JFW For Your Formalwear',
      metaDescription:
        'Choose JFW for exceptional tuxedo rental. Experience impeccable quality, extensive selection, and outstanding service. Elevate your special occasion. Choose JFW!',
    },
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // LAZY-LOADED MODULES
  /////////////////////////////////////////////////////////////////////////////////////////////////
  {
    path: 'checkout',
    loadChildren: () =>
      import('./modules/checkout/checkout.module').then(
        (m) => m.CheckoutModule,
      ),
  },
  {
    path: 'customize-look',
    loadChildren: () =>
      import('@jfw-library/ecommerce/feature-modules/customize-look').then(
        (m) => m.CustomizeLookModule,
      ),
  },
  {
    path: 'ensemble',
    loadChildren: () =>
      import('./modules/ensemble-detail-page/ensemble-detail-page.module').then(
        (m) => m.EnsembleDetailPageModule,
      ),
  },
  {
    path: 'event',
    canActivate: [verifyLoginUserGuard],
    loadChildren: () =>
      import('@jfw-library/ecommerce/feature-modules/event-manager').then(
        (m) => m.EventManagerModule,
      ),
  },
  {
    path: 'my-account',
    loadChildren: () =>
      import('./modules/my-account/my-account.module').then(
        (m) => m.MyAccountModule,
      ),
  },

  // default to /home
  { path: '**', redirectTo: 'home' },
];
