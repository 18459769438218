import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AnonAuthService } from '@jfw-library/ecommerce/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../../../../../environments/environment';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'jfw-prod-login',
  templateUrl: './prod-login.component.html',
  styleUrls: ['./prod-login.component.scss'],
})
export class ProdLoginComponent implements OnInit {
  password: string | undefined;
  invalidPassword: boolean = false;
  prodModeRestricted: boolean = false;
  prodModeNormal: boolean = false;
  showSpinner: boolean = false;
  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  constructor(
    private cookieService: SsrCookieService,
    private anonAuthService: AnonAuthService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.prodModeRestricted = environment.prod_mode_authorization_rules;
    this.prodModeNormal = environment.prod_mode_allow_normal_access;

    if (
      environment.prod_mode_authorization_rules &&
      environment.prod_mode_allow_normal_access &&
      environment.prod_mode_refresh_key !== ''
    ) {
      this.router.navigateByUrl('/home').then(() => {
        if (this.isBrowser) window.location.reload();
      });
    }

    // if (!environment.prod_mode_authorization_rules) {
    //   this.router.navigate(['/V2login']);
    //   return;
    // }

    // if (
    //   environment.prod_mode_authorization_rules &&
    //   environment.prod_mode_allow_normal_access &&
    //   environment.prod_mode_refresh_key !== ''
    // ) {
    //   this.redirectHome(environment.prod_mode_refresh_key);
    // }
  }

  async submitForm() {
    if (!this.password) {
      return;
    }

    this.showSpinner = true;

    const response = await this.anonAuthService.verifyProdMode(this.password);

    console.log('>> prod mode entry response:', response);

    if (!response.valid) {
      this.password = '';
      this.invalidPassword = true;

      this.showSpinner = false;
      return;
    }

    if (response.valid) {
      const redirect = await this.redirectHome(this.password);
    }

    this.showSpinner = false;
  }

  /**
   * Generates a random guid that is used as the session userId for the AnonToken.
   * Sets the token_refresh_key cookie to the password that was entered in the form, which will be used for future token refreshes.
   * Initializes the AnonToken.
   * If the AnonToken is successfully initialized, redirects to /home.
   * @param password The password that was entered in the form.  This is used to set the token_refresh_key cookie.
   */
  async redirectHome(password: string) {
    this.invalidPassword = false;

    //let randomGuid = generateUuid();
    let randomGuid = uuidv4();
    this.cookieService.set('token_refresh_key', password);
    const initTokenAnon = await this.anonAuthService.initAnonToken(randomGuid);

    if (initTokenAnon) {
      await this.router.navigateByUrl('/home');
    }
  }
}
