import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { User } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  EnsembleService,
  NavigationService,
} from '@jfw-library/ecommerce/core';
import { Categories, CategoryItem, LinkType } from 'common-types';
import { environment } from '../../../../../environments/environment';
import { modalSettings } from '../../../../settings/modal-settings/modal-settings';
import { GetStartedModalComponent } from '../../../../standalone/components/modals/get-started/get-started.component';
@Component({
  selector: 'app-header-sidenav',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderSidenavComponent implements OnInit, AfterViewInit {
  @Input() alphaMode: boolean = false;
  @Input() user: User | null = null;
  @Input() isAnon: boolean = false;
  @Output() signOut: EventEmitter<boolean> = new EventEmitter();

  rentalCategoryItems: CategoryItem[] = [];
  purchaseCategoryItems: CategoryItem[] = [];
  howItWorksNav = this.navigationService.howItWorksNav;
  inspirationNav = this.navigationService.inspirationNav;
  myAccountNav = this.navigationService.myAccountNav;
  alphaNav = this.navigationService.alphaNav;
  disableAnimation = true;
  enableGetStarted: boolean = environment.enableEventCreation;
  enableFindAStore: boolean = environment.enableFindAStore;

  constructor(
    private navigationService: NavigationService,
    public dialog: MatDialog,
    private ensembleService: EnsembleService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const categories = new Categories();
    this.rentalCategoryItems = categories.getRentalItems();
    this.purchaseCategoryItems = categories.getPurchaseItems();
  }

  ngAfterViewInit(): void {
    // workaround for angular component issue #13870
    setTimeout(() => (this.disableAnimation = false));
  }

  get linkType(): typeof LinkType {
    return LinkType;
  }

  // navClickFunc(func: string | undefined) {
  //   try {
  //     if (func) {
  //       eval(func);
  //     }
  //   } catch (err) {
  //     console.log(
  //       '%c Nav Click Function Error',
  //       'background: red; color: white'
  //     );
  //     console.log('%c' + err, 'color: red');
  //   }
  // }

  suitsTuxedosNavigation(url: string) {
    const currentUrl = this.router.url;

    // Check if the user is already on the ensembles page
    if (currentUrl.startsWith('/ensembles/suits-and-tuxedos')) {
      this.ensembleService.triggerEnsembleSearch.next({
        triggerSearch: true,
        resetFilterParams: true,
      });
      this.closeSidenav();
      return;
    }

    // If not on the ensembles page, navigate to the target URL
    this.closeSidenav();
    this.router.navigate([url]);
  }

  closeSidenav(): void {
    this.navigationService.showHeaderNav.next(false);
  }

  onSignOut(): void {
    if (confirm('Are you sure you want to sign out?')) {
      if (
        environment.prod_mode_authorization_rules &&
        environment.prod_mode_allow_normal_access &&
        environment.prod_mode_refresh_key !== ''
      ) {
        this.router.navigateByUrl('/logout');
      } else {
        this.router.navigateByUrl('/V2login'); // sign-in currently points to V2LoginComponent (same as /V2login)
      }
    }
  }

  getStarted(): void {
    this.dialog.open(GetStartedModalComponent, {
      autoFocus: false,
      disableClose: true,
      ...modalSettings,
    });
    this.navigationService.showHeaderNav.next(false);
  }
}
