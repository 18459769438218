import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {
  AuthV2Service,
  EventService,
  SignInModalComponent,
} from '@jfw-library/ecommerce/core';
import { firstValueFrom } from 'rxjs';
import { EcomEventService } from '../../services/ecom-event/ecom-event.service';

export const verifyLoginUserGuard = async (
  activatedRouteSnapshot: ActivatedRouteSnapshot,
  routerStateSnapshot: RouterStateSnapshot
): Promise<boolean> => {
  console.log(
    '%c* VERIFY LOGGED IN USER GUARD START *',
    'background-color: Plum;color:black'
  );

  const router = inject(Router);
  const dialog = inject(MatDialog);
  const authV2Service = inject(AuthV2Service);
  const eventService = inject(EventService);
  const ecomEventService = inject(EcomEventService);
  const isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  console.warn('LoggedIn? :', authV2Service.isLoggedIn);
  if (!isBrowser) {
    const isLoggedInOnServer = authV2Service.isLoggedIn;
    console.log(
      'VerifyLoginUserGuard on server. isLoggedIn:',
      isLoggedInOnServer
    );
    return isLoggedInOnServer;
  }

  const isLoggedIn = authV2Service.isLoggedIn
    ? true
    : ((
        await firstValueFrom(
          dialog
            .open(SignInModalComponent, {
              autoFocus: false,
              maxWidth: '340px',
              //minHeight: '600px',
            })
            .afterClosed()
        )
      ).success as boolean);

  if (!isLoggedIn) {
    // NOT logged in (even after trying to log in with SignInComponent)
    console.log(
      '%c* User is not logged in.  Redirecting to home. *',
      'background-color: Plum;color:black'
    );

    router.navigate(['']);
    return false;
  }

  // const routeEventId = activatedRouteSnapshot.params.eventId;
  const routeEventId =
    routerStateSnapshot.root.children[0].children[0].params.eventId;
  // console.log("routeEventId", routeEventId);

  // User is logged in.  Check if the event is in their UserEvents
  try {
    const hasEvent = (await ecomEventService.getUserEvents(true)).some(
      (userEvent) => userEvent.id === routeEventId
    );
    if (hasEvent) {
      console.log(
        `%c* Event found in UserEvents.
        Setting selected event and routing to eventManager. *`,
        'background-color: Plum;color:black'
      );

      await eventService.setSelectedEvent(routeEventId, 'verifyLoginUserGuard');
      return true;
    }
  } catch (error: any) {
    if (error?.status === 404) {
      console.warn(`Event not found in UserEvents.  Redirecting to home.`);
    } else {
      console.error('Error fetching UserEvents. Will redirect to home.', error);
    }
    router.navigate(['']);
    return false;
  }

  console.log(
    '%c* Event not found in UserEvents.  Redirecting to home screen. *',
    'background-color: Plum;color:black'
  );

  router.navigate(['']);
  return false;
};
