import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '@angular/fire/auth';
import { EnsembleService, StyleService } from '@jfw-library/ecommerce/core';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss'],
})
export class HeaderMobileComponent {
  @Input() user: User | null = null;
  @Input() isAnon!: boolean;

  @Output() sidenavEvent = new EventEmitter<void>();
  @Output() searchEvent = new EventEmitter<void>();
  @Output() anonEvent = new EventEmitter<void>();

  constructor(
    private styleService: StyleService,
    private ensembleService: EnsembleService,
  ) {}

  onSidenav(): void {
    this.styleService.showFilter.next(false);
    this.ensembleService.showFilter.next(false);
    this.sidenavEvent.next();
  }

  onSearch(): void {
    this.searchEvent.next();
  }

  onAnon(): void {
    this.anonEvent.next();
  }
}
