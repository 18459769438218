import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { EnsembleService } from '@jfw-library/ecommerce/core';
import { setColorClass } from 'business-logic';
import { FilterCategory } from 'common-types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ensemble-filters',
  templateUrl: './ensemble.component.html',
  styleUrls: ['./ensemble.component.scss'],
})
export class EnsembleFiltersComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  filterCategories: Array<FilterCategory> | undefined;
  // @Input() filterCategories: FilterCategory[] | undefined;
  // @Output() filterSelectedOutput = new EventEmitter<any>();
  colorMap: Record<string, string> = {};

  constructor(private ensembleService: EnsembleService) {}

  ngOnInit(): void {
    if (this.filterCategories === undefined) {
      this.subscription.add(
        this.ensembleService.filterCategories.subscribe({
          next: (filterCategories) => {
            this.filterCategories = filterCategories;
          },
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setColorClass(colorFamily: string, checkbox?: MatCheckbox): string {
    return setColorClass(colorFamily, checkbox);
  }

  rentOrBuyMask(option: string): string {
    let maskedOption = option;
    switch (option) {
      case 'Rental':
        maskedOption = 'Rent';
        break;
      case 'Purchase':
        maskedOption = 'Buy';
        break;
      case 'Rental/Purchase':
        maskedOption = 'Rent/Buy';
        break;
    }
    return maskedOption;
  }

  public optionClicked(
    category: FilterCategory,
    categoryIndex: number,
    optionIndex: number,
  ): void {
    // Single Selector logic
    // if (category.select === FilterSelect.Single) {
    //   this.filterCategories![categoryIndex].options?.forEach(
    //     (option, index) => {
    //       if (index !== optionIndex) {
    //         option.selected = false;
    //       }
    //     }
    //   );
    // }
    console.log('filter > optionsClicked()');
    // this.filterSelectedOutput.emit(category);
    this.ensembleService.triggerEnsembleSearch.next(true);
    //this.ensembleService.searchEnsembles.next();
  }
}
