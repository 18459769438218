import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { User } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import {
  AuthV2Service,
  EventService,
  NavigationService,
  SignInModalComponent,
} from '@jfw-library/ecommerce/core';
import { AfterSignIn } from 'common-types';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { modalSettings } from '../../../settings/modal-settings/modal-settings';
import { GetStartedModalComponent } from '../../../standalone/components/modals/get-started/get-started.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input()
  set user(user: User | null) {
    this._user = user;
    console.log(
      'Input() set user in HeaderComponent -- user:',
      user ? user?.displayName : null,
    );
  }
  get user() {
    return this._user;
  }
  private _user: User | null = null;

  @Input()
  set isAnon(isAnon: boolean) {
    this._isAnon = isAnon;
    console.log(
      'Input() set isAnon in HeaderComponent -- isAnon:',
      this.isAnon,
    );
  }
  get isAnon() {
    return this._isAnon;
  }
  private _isAnon = false;

  @Input() alphaMode = false;

  @ViewChild(MatMenuTrigger) ocMenuTrigger!: MatMenuTrigger;
  @ViewChild(MatMenuTrigger) hiwMenuTrigger!: MatMenuTrigger;
  @ViewChild(MatMenuTrigger) inspirationMenuTrigger!: MatMenuTrigger;

  subscription = new Subscription();
  showMobileSearch = false;
  servedEnvironment: string = 'none';
  showPanic = environment.envVersion === 'default' || environment.envVersion === 'local';
  disablePanic = false;
  eventDocListenerActive = false;
  numEventDocUpdates$ = this.eventService.numEventDocUpdatesReceived$;

  constructor(
    private authV2Service: AuthV2Service,
    private dialog: MatDialog,
    private eventService: EventService,
    private navigationService: NavigationService,
  ) { }

  ngOnInit(): void {
    this.setServeEnvironment();

    this.subscription.add(
      this.navigationService.showMobileSearch.subscribe((value) => {
        this.showMobileSearch = value;
      }),
    );

    // open get started modal with completed event
    // after user creates account/signs in
    this.subscription.add(
      this.authV2Service.afterSignIn$.subscribe({
        next: (afterSignIn) => {
          if (afterSignIn === AfterSignIn.CreateGetStartedEvent) {
            this.onGetStarted();
          }
        },
      }),
    );

    this.subscription.add(
      this.eventService.eventDocListenerPanic$.subscribe(value => this.disablePanic = value)
    )

    this.subscription.add(
      this.eventService.eventDocListenerActive$.subscribe(value => {
        this.eventDocListenerActive = value;
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public panicEventDocListener(): void {
    console.log("PANIC -- Removing Event Doc Listener...");
    this.eventService.panicEventDocListener();
  }


  setServeEnvironment() {
    this.servedEnvironment = environment.envVersion;
  }

  onGetStarted(): void {
    this.dialog.open(GetStartedModalComponent, {
      autoFocus: false,
      disableClose: true,
      ...modalSettings,
    });
  }

  onAnon(): void {
    this.dialog.open(SignInModalComponent, {
      autoFocus: false,
      ...modalSettings,
    });
  }

  navigateToEvent(): void {
    this.eventService.routeToFirstStep();
  }

  onSidenav(): void {
    console.log('onSidenav');
    this.navigationService.showHeaderNav.next(true);
  }

  onSearch(): void {
    this.navigationService.showMobileSearch.next(!this.showMobileSearch);
  }
}
