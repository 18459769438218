import { ConsoleLogFlags } from "./console-log-flags.type";

/**
 * Disables/Enables console output based on the flags passed in.
 * @param flags Object containing the flags for which console functions to enable/disable.
 * @returns The flags that were disabled/enabled.  This is useful for logging the flags that were enabled/disabled
 */
export const disableConsole = (flags: ConsoleLogFlags) => {
  const { log, logAll, warn, error, info, debug } = flags;

  if (logAll) { // enable all console functions
    console.log = getNewConsoleLog(console.log, true);
    console.warn = getNewConsoleLog(console.warn, true);
    console.error = getNewConsoleLog(console.error, true);
    console.info = getNewConsoleLog(console.info, true);
    console.debug = getNewConsoleLog(console.debug, true);
  }

  else { // enable/disable console functions based on flags
    console.log = getNewConsoleLog(console.log, log);
    console.warn = getNewConsoleLog(console.warn, warn);
    console.error = getNewConsoleLog(console.error, error);
    console.info = getNewConsoleLog(console.info, info);
    console.debug = getNewConsoleLog(console.debug, debug);
  }

  return flags;
}






/**
 * Returns a new console function that will only log if display is true.
 * @param original The original console function (can be console.log, .warn, .error, .debug, or .info).
 * @param display Whether or not to display the console.log.
 */
const getNewConsoleLog = (original: typeof console.log, display: boolean) => {
  // var original = console.log;
  if (display == false) return function () { };
  return (...data: any[]): void => {
    // .call is used to call the .log function with a new this context,
    // but we don't want to change the original console.log context,
    // so we call it with the original console as the context.
    // The first argument to .call is the new context to use,
    // and the rest of the arguments are the arguments to pass to the function.
    // the .log function is called with the original console as the context,
    // but with the new message and optionalParams as the arguments
    original.call(console, ...data);
  };
};
