import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch, withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { getApp } from '@angular/fire/app';
import {
  ReCaptchaEnterpriseProvider,
  initializeAppCheck,
  provideAppCheck,
} from '@angular/fire/app-check';
import { appCheckInterceptor } from '@jfw-library/shared/app-check';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppModule } from './app.module';
import { TokenInterceptor } from './core/interceptors/token/token.interceptor';

@NgModule({
  imports: [AppModule],
  providers: [
    // provideClientHydration(),
    provideAppCheck(() =>
      initializeAppCheck(getApp(), {
        provider: new ReCaptchaEnterpriseProvider(
          environment.recaptchaAppCheckKey
        ),
        isTokenAutoRefreshEnabled: false, // this is also the default
      })
    ),
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: AppCheckInterceptor, multi: true },
    provideHttpClient(
      withFetch(),
      withInterceptorsFromDi(), // allows non-functional interceptors (eventually remove this once all interceptors are functional)
      withInterceptors([appCheckInterceptor]), // functional interceptors go here
    ),
    // provideAuth(() => initializeAuth(getApp())),

  ],
  bootstrap: [AppComponent],
})
export class AppBrowserModule {
  constructor() {
    console.log('AppBrowserModule constructor');
  }
}
