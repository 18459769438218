import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  EnsembleService,
  NavigationService,
  StyleService,
} from '@jfw-library/ecommerce/core';
import { FilterCategory } from 'common-types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-plp-filter-sidenav',
  templateUrl: './plp-filter.component.html',
  styleUrls: ['./plp-filter.component.scss'],
})
export class PlpFilterSidenavComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  // @Input() filterCategories: FilterCategory[] | undefined;
  // @Output() filterSelected = new EventEmitter<any>();
  disableAnimation = true;
  showStylesFilters = false;
  showEnsembleFilters = false;
  filterCategories: Array<FilterCategory> | undefined;

  subscription = new Subscription();
  constructor(
    private navigationService: NavigationService,
    private styleService: StyleService,
    private ensembleService: EnsembleService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.styleService.showFilter.subscribe((value: boolean) => {
        this.showStylesFilters = value;
      }),
    );

    this.subscription.add(
      this.ensembleService.showFilter.subscribe((value: boolean) => {
        this.showEnsembleFilters = value;
      }),
    );

    this.subscription.add(
      this.ensembleService.filterCategories.subscribe({
        next: (filterCategories) => {
          this.filterCategories = filterCategories;
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  clearFilters() {
    this.ensembleService.triggerEnsembleSearch.next({
      triggerSearch: true,
      resetFilterParams: true,
    });
  }

  filterCategoriesSelected() {
    if (this.filterCategories === undefined) {
      return false;
    }

    let selected = false;

    this.filterCategories.forEach((category) => {
      category.options?.forEach((option) => {
        if (option.selected) {
          selected = true;
        }
      });
    });

    return selected;
  }

  ngAfterViewInit(): void {
    // workaround for angular component issue #13870
    setTimeout(() => (this.disableAnimation = false));
  }

  close(): void {
    this.styleService.showFilter.next(false);
    this.ensembleService.showFilter.next(false);
    this.navigationService.showPlpNav.next(false);
  }

  // filterSelectedOutput(event: any) {
  //   console.log('plp-filter triggered');
  //   console.log(event);
  //   // this.searchEnsembles();
  //   this.filterSelected.emit(event);
  // }
}
