<!-- deploy 59 -->
<ng-container *ngIf="maintenance; else elseBlock">
  <app-maintenance></app-maintenance>
</ng-container>
<ng-template #elseBlock>
  <a
    class="skip-main"
    #skipRefHeader
    (click)="skipMain($event, 'header')"
    href="#"
  >
    Skip to Header
  </a>
  <a
    class="skip-main"
    #skipRefMainevent
    (click)="skipMain($event, 'main-app')"
    href="#"
  >
    Skip to Main Content
  </a>
  <a
    class="skip-main"
    #skipRefFooter
    (click)="skipMain($event, 'footer')"
    href="#"
  >
    Skip to Footer
  </a>

  <mat-sidenav-container (backdropClick)="closeSideNav()">
    <mat-sidenav
      *ngIf="!disableHeaderFooter"
      #sidenav
      [position]="sidenavPosition"
      fixedInViewport="true"
      autoFocus="false"
      [class]="showPlpNav ? 'plp-nav' : ''"
    >
      <app-header-sidenav
        *ngIf="showHeaderNav"
        [user]="user$ | async"
        [isAnon]="(isAnon$ | async) ?? false"
        [alphaMode]="alphaMode"
      ></app-header-sidenav>

      <app-plp-filter-sidenav *ngIf="showPlpNav"></app-plp-filter-sidenav>

      <app-customize-look-filters
        *ngIf="showCustomizeLookNav"
      ></app-customize-look-filters>
    </mat-sidenav>

    <mat-sidenav-content>
      <header
        class="header"
        id="header"
        *ngIf="!disableHeaderFooter"
        [class]="collapsableHeader && hideHeader ? 'hidden' : ''"
      >
        <app-header
          [user]="user$ | async"
          [isAnon]="(isAnon$ | async) ?? false"
          [alphaMode]="alphaMode"
        ></app-header>
      </header>

      <div *ngIf="msg !== ''" class="msg">{{ msg }}</div>

      <main class="main-app" [class]="routerCss">
        <router-outlet></router-outlet>
      </main>

      <div *ngIf="alphaMode && (user$ | async) !== null" class="alpha-mode">
        <span>width: {{ screenWidth }}</span>
      </div>

      <footer class="footer" *ngIf="!disableHeaderFooter">
        <app-footer></app-footer>
      </footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-template>
