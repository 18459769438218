import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthV1Service, AuthV2Service } from '@jfw-library/ecommerce/core';
import {
  SignInFormControls,
  ZodFormUtilities,
} from '@jfw-library/ecommerce/zod-forms';
import { SignInFormSchema, Site } from 'common-types';

export interface UserGoogleProfile {
  uid?: string;
  email?: string;
  displayName?: string;
  photoURL?: string;
  disabled?: boolean;
  customClaims?: any;
  disabling?: boolean;
}

@Component({
  selector: 'app-email-password-form',
  templateUrl: './email-password-form.component.html',
  styleUrls: ['./email-password-form.component.scss'],
})
export class EmailPasswordFormComponent implements OnInit {
  signInForm = new FormGroup<SignInFormControls>(
    {
      email: new FormControl('', {
        nonNullable: true,
      }),
      password: new FormControl('', {
        nonNullable: true,
      }),
    },
    [ZodFormUtilities.zodFormGroupValidator(SignInFormSchema)]
  );

  // user: firebase.User | null = null;
  // guestModeEnabled: boolean;
  // invalidCredentials = false;
  // permittedExternalEmails: string[] = environment.permittedExternalEmails;

  constructor(
    public authV1Service: AuthV1Service,
    private authV2Service: AuthV2Service,
    // public afAuth: AngularFireAuth,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    // this.guestModeEnabled = environment.enableGuestEmulationMode;
  }

  ngOnInit(): void {}

  onSignIn(): void {
    if (
      this.signInForm.valid &&
      this.signInForm.value.email &&
      this.signInForm.value.password
    ) {
      this.authV2Service.signIn(
        Site.Ecom,
        this.signInForm.value.email,
        this.signInForm.value.password,
        '/home'
      );
    }
  }
}
