/**
 * This library is for shared UI logic that is used across multiple apps (platform and ecommerce).
 * This library is not for shared business logic.  use business-logic for that.
 * This library is not for shared UI components.  use @jfw-library/common-components for that.
 * This should be used for shared functions that might be used in components, services, etc.
 * Include types only if they are truly self-contained to the logic within this library.  In other words, don't export any types in the public-api from this library.
 * If there are types that will need to be imported from outside this library, then put those types in @common-types.
 * For example, the console.log override is used in both platform and ecommerce, but it is not a component or a service.  It is a function.
 * The ConsoleLogFlags type is only used internally and not exported outside of this library, so it is included here.
 */

export * from './src/console';
