import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
  afterNextRender,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EnsembleService } from '@jfw-library/ecommerce/core';
import { getEnsembleDesigner, getImage } from 'business-logic';
import { EcomEnsemble, Image } from 'common-types';
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'app-home-page-copy',
  templateUrl: './home-page-copy.component.html',
  styleUrls: ['./home-page-copy.component.scss'],
})
export class HomePageCopyComponent implements OnInit, AfterViewChecked {
  ensembles$: Observable<EcomEnsemble[]> | undefined;

  constructor(
    private cdRef: ChangeDetectorRef,
    private ensembleService: EnsembleService,
    public dialog: MatDialog
  ) {
    // afterRender(() => {
    //   console.log('After render');
    // });

    afterNextRender(() => {
      console.log('After next render');
      this.ensembles$ = this.ensembleService
        .getTrendingEnsembles()
        .pipe(tap((ensembles) => console.log('Ensembles received')));
    });
  }

  ngOnInit(): void {}

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  public getImage(ensemble: EcomEnsemble): Image {
    return getImage(ensemble);
  }

  public getDesigner(ensemble: EcomEnsemble): string {
    return getEnsembleDesigner(ensemble);
  }

  // public getStarted(): void {
  //   this.dialog.open(GetStartedModalComponent, {
  //     width: '400px',
  //     maxWidth: '300px',
  //     height: '600px',
  //     autoFocus: false,
  //     disableClose: true,
  //   });
  // }
}
