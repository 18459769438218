<div class="header">
  <div class="menu-items">
    <button mat-icon-button (click)="onSidenav()">
      <mat-icon>menu</mat-icon>
    </button>

    <button mat-icon-button title="Search" (click)="onSearch()">
      <mat-icon>search</mat-icon>
    </button>
  </div>

  <div class="home">
    <a routerLink="/home">
      <img
        src="assets/images/jfw-logos/logo.png"
        alt="Logo for Jim's Formal Wear"
        class="logo"
      />
    </a>
  </div>

  <div class="accounts">
    @if (!isAnon && user) {
      <button
        mat-icon-button
        class="account-button"
        routerLink="/my-account/events"
      >
        <div>
          <span>{{ user.displayName ? user.displayName[0] : "" }}</span>
        </div>
      </button>
    }

    @if (isAnon) {
      <button mat-icon-button title="Guest - Click to Login" (click)="onAnon()">
        <mat-icon>account_circle</mat-icon>
      </button>
    }
  </div>
</div>
<app-search-mobile></app-search-mobile>
