import { IMAGE_CONFIG, IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';
import { APP_ID, APP_INITIALIZER, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';

import { getPerformance, providePerformance } from '@angular/fire/performance';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  provideRouter,
  RouterModule,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
} from '@angular/router';
import { EventManagerEnvironmentService } from '@jfw-library/ecommerce/feature-modules/event-manager';
import {
  IConfig,
  NgxMaskDirective,
  NgxMaskPipe,
  provideNgxMask,
} from 'ngx-mask';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { CoreModule } from './core/core.module';
import { ECOMMERCE_MAIN_ENVIRONMENT } from './core/tokens/ecommerce-main-environment.token';

export const maskOptions: Partial<null | IConfig> | (() => Partial<IConfig>) =
  null;

// export const FIREBASE_ADMIN = new InjectionToken<app.App>('firebase-admin');

// let appCheck: AppCheck | undefined;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    // HttpClientModule,
    MatNativeDateModule,
    MatSidenavModule,
    NgxMaskDirective,
    NgxMaskPipe,
    RouterModule,
  ],
  providers: [
    provideRouter(
      routes,
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      })
    ),
    provideClientHydration(),
    // provideHttpClient(
    //   withFetch(),
    //   withInterceptorsFromDi(),
    //   // withInterceptors([ssrInterceptor]),
    // ),
    // { provide: APP_ID, useValue: 'serverApp' }, // not needed because only bootstrapping one app on the page.
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    // provideAppCheck((injector) => {
    //   const admin = injector.get<app.App | null>(FIREBASE_ADMIN, null);

    //   if (admin) {
    //     if (appCheck) {
    //       console.log("provideAppCheck on server.  AppCheck already initialized...");
    //       return appCheck;
    //     }

    //     const provider = new CustomProvider({
    //       getToken: () =>
    //         admin.
    //           appCheck().
    //           createToken(environment.firebaseConfig.appId, { ttlMillis: 604_800_000, /* 1 week */ }).
    //           then(({ token, ttlMillis: expireTimeMillis }) => ({ token, expireTimeMillis }))
    //     });
    //     appCheck = initializeAppCheck(undefined, { provider, isTokenAutoRefreshEnabled: false });
    //     console.log("provideAppCheck on server. Providing CustomProvider...");
    //     return appCheck;
    //   } else {
    //     console.log("provideAppCheck admin is null. Initializing ReCaptchaEnterpriseProvider...");
    //     const provider = new ReCaptchaEnterpriseProvider(environment.recaptchaAppCheckKey);
    //     return initializeAppCheck(undefined, { provider, isTokenAutoRefreshEnabled: true });
    //   }
    // }, [new Optional(), FIREBASE_ADMIN]),
    provideAuth(() => getAuth()),
    provideFirestore(() => {
      const firestore = getFirestore();
      // connectFirestoreEmulator(firestore, 'localhost', 8080);
      // enableIndexedDbPersistence(firestore);
      return firestore;
    }),
    providePerformance(() => getPerformance()),
    provideNgxMask(),
    {
      provide: APP_INITIALIZER,
      useFactory: (envService: EventManagerEnvironmentService) => () => {
        envService.setEnvironment(environment);
      },
      deps: [EventManagerEnvironmentService],
      multi: true,
    },
    {
      provide: 'environment',
      useValue: environment,
    },
    {
      provide: ECOMMERCE_MAIN_ENVIRONMENT,
      useValue: environment,
    },
    /* {     // moved to app.server.module.ts
      provide: APP_INITIALIZER,
      useFactory: (platformId: typeof PLATFORM_ID) => () => {
        const isBrowser = isPlatformBrowser(platformId);
        if (!isBrowser) {
          console.log("Overriding localStorage and sessionStorage methods for server-side rendering");
          global['localStorage'] = {
            getItem: (key: string) => null,
            setItem: (key: string, value: string) => {
              console.log(`Server localStorage.setItem ignored for key: ${key}, value: ${value}`);
            },
            removeItem: (key: string) => {
              console.log(`Server localStorage.removeItem ignored for key: ${key}`);
            },
            clear: () => {
              console.log(`Server localStorage.clear ignored`);
            },
            length: 0,
            key: () => null,
          }
        }

      },
      deps: [PLATFORM_ID],
      multi: true,
    }, */
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: IMAGE_CONFIG,
      useValue: {
        breakpoints: [16, 48, 96, 128, 384, 640, 750, 828, 1080, 1200, 1920],
      },
    },
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        if (config.src.startsWith('assets/')) {
          return `${config.src}`;
        } else {
          const url = `${environment.cdnUrl}/${config.width}x${
            config.width! * 1.5
          }%2F${config.src}?alt=media`;
          return url;
        }
      },
    },
    { provide: APP_ID, useValue: 'serverApp' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
