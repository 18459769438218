import { ConsoleLogFlags } from "./console-log-flags.type";

/**
 * Gets the console query params from the URL.
 * If no query params are set, then all flags will be false.
 * If any query params are set, then the flags will be set to true.
 * If logAll is set, then all flags will be set to true.
 * If run in a non-browser environment (SSR), then all flags will be false.
 * @returns The console query params.
 */
export const getConsoleQueryParams = (): ConsoleLogFlags => {
  if (typeof window === 'undefined') return { log: false, logAll: false, warn: false, error: false, info: false, debug: false };
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const log = urlParams.get('jfwConsoleLog') === 'true';
  const logAll = urlParams.get('jfwConsoleLogAll') === 'true';
  const warn = urlParams.get('jfwConsoleWarn') === 'true';
  const error = urlParams.get('jfwConsoleError') === 'true';
  const info = urlParams.get('jfwConsoleInfo') === 'true';
  const debug = urlParams.get('jfwConsoleDebug') === 'true';
  // console.log({ log, logAll, warn, error, info, debug });
  return { log, logAll, warn, error, info, debug };
}
