<div [@.disabled]="disableAnimation">
  <div class="sidenav-header">
    <h2>Filter!</h2>
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <app-style-filters *ngIf="showStylesFilters"></app-style-filters>
  <!-- <app-ensemble-filters
    *ngIf="showEnsembleFilters"
    [filterCategories]="filterCategories"
    (filterSelectedOutput)="filterSelectedOutput($event)"
  ></app-ensemble-filters> -->
  <app-ensemble-filters *ngIf="showEnsembleFilters"></app-ensemble-filters>
</div>
