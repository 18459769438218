import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthV2Service } from '@jfw-library/ecommerce/core';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  private authV2Service = inject(AuthV2Service);

  constructor(private router: Router) {}

  ngOnInit(): void {
    console.log('got to logout page?');
    this.authV2Service.signOut();

    if (
      environment.prod_mode_authorization_rules &&
      environment.prod_mode_allow_normal_access &&
      environment.prod_mode_refresh_key !== ''
    ) {
      this.router.navigateByUrl('/home').then(() => {
        if (this.isBrowser) window.location.reload();
      });
    } else {
      this.router.navigateByUrl('/V2login').then(() => {
        if (this.isBrowser) window.location.reload();
      });
    }
  }
}
