<div [@.disabled]="disableAnimation">
  <div class="sidenav-header" *ngIf="showStylesFilters">
    <h2>Filter</h2>
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="sidenav-header" *ngIf="showEnsembleFilters">
    <h2>Filter</h2>
    <button
      *ngIf="filterCategoriesSelected()"
      mat-flat-button
      color="accent"
      style="
        margin-top: 2px;
        margin-left: 10px;
        max-height: 24px;
        padding: 0px;
        font-size: 0.6em !important;
      "
      (click)="clearFilters()"
    >
      Clear
    </button>
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <app-style-filters *ngIf="showStylesFilters"></app-style-filters>
  <!-- <app-ensemble-filters
    *ngIf="showEnsembleFilters"
    [filterCategories]="filterCategories"
    (filterSelectedOutput)="filterSelectedOutput($event)"
  ></app-ensemble-filters> -->
  <app-ensemble-filters *ngIf="showEnsembleFilters"></app-ensemble-filters>
</div>
