import { Component } from '@angular/core';
import { NavigationService } from '@jfw-library/ecommerce/core';
import { LinkType } from 'common-types';
// import packageJson from '../../../../../../../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  supportNav = this.navigationService.supportNav;
  forRetailersNav = this.navigationService.forRetailersNav;
  aboutUsNav = this.navigationService.aboutUsNav;

  libVersion = ''; // packageJson.version;
  buildNumber = ''; // packageJson.buildNumber;
  buildDate = ''; // packageJson.buildDate;

  constructor(private navigationService: NavigationService) {}

  get linkType(): typeof LinkType {
    return LinkType;
  }
}
