import { AfterContentInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AnonAuthService,
  AuthService,
  CartService,
  EventService,
} from '@jfw-library/ecommerce/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-sign-in-v2',
  templateUrl: './sign-in-v2.component.html',
  styleUrls: ['./sign-in-v2.component.scss'],
})
export class SignInV2Component implements OnInit, AfterContentInit {
  error: string = '';
  verified: string = '';
  loadingLogin: boolean = true;
  authSubject = new Subject<boolean>();
  isAuth = new Observable<boolean>();
  displayEmailPasswordForm: boolean = false;
  isProdWideOpen: boolean = false;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private eventService: EventService,
    private cartService: CartService,
    private anonAuthService: AnonAuthService,
    private router: Router,
  ) {
    this.authSubject.next(this.authService.isLoggedIn);
    this.isAuth = this.authSubject.asObservable();
  }

  ngAfterContentInit(): void {}

  ngOnInit(): void {
    if (
      environment.prod_mode_authorization_rules &&
      environment.prod_mode_allow_normal_access &&
      environment.prod_mode_refresh_key !== ''
    ) {
      this.isProdWideOpen = true;
    }

    console.log(
      '%cSignInV2Component_OnInit',
      'background-color:red;color:white',
    );

    if (
      !environment.prod_mode_authorization_rules ||
      !environment.prod_mode_allow_normal_access
    ) {
      this.anonAuthService.clearAnonTokenCookies();
    }

    /// TODO: Might consider reimplementing this,
    /// but for now, commenting it out to avoid any unexpected
    /// infinite loops
    // if (
    //   environment.prod_mode_authorization_rules &&
    //   environment.prod_mode_allow_normal_access &&
    //   environment.prod_mode_refresh_key !== ''
    // ) {
    //   this.router.navigate(['/home']).then(() => {
    //     //window.location.reload();
    //   });
    // }

    // console.log('V2login -> ngOnInit');
    this.authService.signOut().then(() => {
      // localStorage.clear(); // this is done in the SignOut() method
      this.cartService.clearCartCache();
      this.eventService.selectedEvent = null;

      /// If in Full/Wide-open prod mode, then redirect to home page
      if (
        environment.prod_mode_authorization_rules &&
        environment.prod_mode_allow_normal_access &&
        environment.prod_mode_refresh_key !== ''
      ) {
        this.router.navigateByUrl('/home');
        return;
      }

      // currently these params are only added to the redirect url when prod_mode_authorization_rules is false.
      this.route.queryParams.subscribe((params) => {
        if (params.error === 'noaccess') {
          this.error = 'Your account does not have access.';
        }
        if (params.verified === 'false') {
          this.verified =
            'Your account has not been verified. Check your email.';
        }
        //this.loadingLogin = false;
      });

      this.loadingLogin = false;
    });

    //}, 500);
    //}
  }

  showHideEmailPasswordForm() {
    this.displayEmailPasswordForm = !this.displayEmailPasswordForm;
  }

  async signInWithGoogle() {
    this.anonAuthService.clearAnonTokenCookies();
    await this.authService.googleAuth();
  }
}
