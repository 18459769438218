<div class="main-desk">
  <div class="product-listing-header-mobile" id="listing-anchor">
    <h1>{{ categoryItem?.displayName }}</h1>
  </div>
  <button
    (click)="showMobileFilters()"
    mat-stroked-button
    color="accent"
    class="filters-mobile"
  >
    <div class="filter-button-container">
      <span>Filter</span>
      <mat-icon>tune</mat-icon>
    </div>
  </button>
  <div style="margin-top: 20px" class="mobile" *ngIf="!emptyResults">
    <mat-paginator
      #paginator
      [pageSizeOptions]="[12, 24, 48]"
      [pageSize]="this.pageSize"
      [length]="this.resultSize"
      [pageIndex]="this.pageIndex"
      aria-label="Select page of styles"
      (page)="change($event)"
    ></mat-paginator>
  </div>
  <div *ngIf="emptyResults" class="empty-result-container-mobile">
    <span>No results found. Please change your selections.</span>
  </div>

  <div class="center-desk">
    <div class="filters-desk">
      <h3>Filter</h3>
      <!-- <app-ensemble-filters
        [filterCategories]="filterCategories"
        (filterSelectedOutput)="filterSelected($event)"
      ></app-ensemble-filters> -->
      <app-ensemble-filters></app-ensemble-filters>
    </div>

    <!-- <ng-template #loadingSpinner>
      <div class="spinner-container">
        <mat-spinner></mat-spinner>
      </div>
    </ng-template> -->

    <div class="plp-desk">
      <div class="product-listing-header-desktop">
        <div class="product-header">
          <h1>{{ categoryItem?.displayName }}</h1>
          <!-- <ng-template #loadingSpinner>
            <div class="spinner-container">
              <mat-spinner></mat-spinner>
            </div>
          </ng-template> -->

          <div
            [style.display]="!ensemblesFromAsync$ || emptyResults ? 'none' : ''"
          >
            <mat-paginator
              #paginator
              [pageSizeOptions]="[12, 24, 48]"
              [pageSize]="this.pageSize"
              [length]="this.resultSize"
              [pageIndex]="this.pageIndex"
              aria-label="Select page of styles"
              (page)="change($event)"
            ></mat-paginator>
          </div>
        </div>
        <span *ngIf="emptyResults"
          >No results found. Please change your selections.</span
        >
        <!-- <p>{{ categoryItem?.description }}</p> -->
      </div>

      <!-- <div class="spinner-container" *ngIf="loadingSearch">
          <mat-spinner></mat-spinner>
        </div> -->

      <ng-template #loadingSpinner>
        <div style="position: relative; width: 100%">
          <div class="spinner-container-enabled">
            <mat-spinner></mat-spinner>
          </div>
        </div>
      </ng-template>
      <div
        class="plp-desk"
        *ngIf="ensemblesFromAsync$ | async as ensembles; else loadingSpinner"
      >
        <div
          class="plp-col-desk"
          *ngFor="let ensemble of ensembles; let imageIndex = index"
        >
          <!-- Test defer -->
          <!-- @defer (on viewport){ -->
          <div class="plp-item">
            <a
              routerLink="{{ ['/ensemble/' + ensemble.ensembleCode] }}"
              queryParamsHandling="preserve"
            >
              <img
                class="plp-image-desk"
                ngSrc="{{ getImage(ensemble).src }}"
                [alt]="
                  'Click to navigate to the details page for ' + ensemble.title
                "
                sizes="(max-width: 299px) 85vw, (max-width: 599px) 28vw, 25vw"
                [priority]="imageIndex < 3 ? true : false"
                fill
              />

              <p class="product-title">{{ ensemble.title }}</p></a
            ><br />

            <div class="pricing-container">
              <hr />
              <span class="price-descript">Starting At:</span>
              <div>
                <span class="price" *ngIf="getRentPrice(ensemble) > 0"
                  >Rent: {{ getRentPrice(ensemble) | currency }}
                </span>
              </div>
              <div>
                <span class="price" *ngIf="getBuyPrice(ensemble) > 0"
                  >Buy: {{ getBuyPrice(ensemble) | currency }}</span
                >
              </div>
            </div>
          </div>
          <!-- }@placeholder (minimum 100ms) {
          <img
            alt="placeholder"
            src="assets/images/style-image-placeholder.png"
            style="background-color: silver; width: 100%; height: auto"
            width="640"
            height="940"
          />
          } -->
        </div>
      </div>
    </div>
  </div>

  <div [style.display]="!ensemblesFromAsync$ || emptyResults ? 'none' : ''">
    <mat-paginator
      #paginator
      [pageSizeOptions]="[12, 24, 48]"
      [pageSize]="this.pageSize"
      [length]="this.resultSize"
      [pageIndex]="this.pageIndex"
      aria-label="Select page of ensembles"
      (page)="change($event)"
    ></mat-paginator>
  </div>
</div>
