import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

export const ProductSearchGuard = (
  activatedRouteSnapshot: ActivatedRouteSnapshot,
  routerStateSnapshot: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const queryParams = activatedRouteSnapshot.queryParams;
  if (Object.keys(queryParams).length === 1 && queryParams['searchText']) {
    return true; // Allow access to the route
  } else {
    router.navigate(['/ensembles/suits-and-tuxedos']); // Redirect to anotherRoute
    return false; // Prevent access to the original route
  }
};
