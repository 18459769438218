<div class="parent" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
  <form [formGroup]="form" (ngSubmit)="onSearch()">
    <mat-form-field [ngClass]="{ expand: expandInput }">
      <button matPrefix mat-icon-button class="search-button">
        <mat-icon>search</mat-icon>
      </button>
      <label for="search" style="display: none">Search</label>
      <input
        id="search"
        matInput
        #input="matInput"
        formControlName="search"
        (blur)="onBlur()"
      />
      @if (hasValue()) {
        <button matSuffix mat-icon-button type="button" (click)="onClear()">
          <mat-icon>close</mat-icon>
        </button>
      }
    </mat-form-field>
  </form>
</div>
