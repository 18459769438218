import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthV2Service } from '@jfw-library/ecommerce/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-logout-proxy',
  templateUrl: './logout-proxy.component.html',
  styleUrls: ['./logout-proxy.component.scss'],
})
export class LogoutProxyComponent implements OnInit {
  private authV2Service = inject(AuthV2Service);

  constructor(
    private router: Router,
    private cookieService: SsrCookieService
  ) {}

  ngOnInit(): void {
    //this.router.navigate(['/V2login']);

    console.log('logout-proxy init');

    this.authV2Service.signOut();
    // this.router.navigateByUrl('/V2login').then(() => {
    //   window.location.reload();
    // });

    if (
      environment.prod_mode_authorization_rules &&
      this.cookieService.get('token_prod_session_userid') &&
      this.cookieService.get('token_prod')
    ) {
      this.router.navigate(['/home']);
    } else {
      this.router.navigate(['/V2login']);
    }
  }
}
