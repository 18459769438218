import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NavigationService } from '@jfw-library/ecommerce/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-mobile',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchMobileComponent implements OnInit {
  formGroup: UntypedFormGroup;
  showSearch: boolean = false;
  subs: Subscription[] = [];

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.formGroup = this.formBuilder.group({
      search: '',
    });
  }

  ngOnInit(): void {
    this.subs.push(
      this.navigationService.showMobileSearch.subscribe((value) => {
        this.showSearch = value;
      }),
    );
  }

  searchForIt(): void {
    console.log('Search data is: ' + this.formGroup.value['search']);
    let searchString = this.formGroup.value['search'];
    this.formGroup.controls.search.setValue('');
    this.navigationService.showMobileSearch.next(false);
    this.router.navigate(['/products'], {
      queryParams: { searchText: searchString },
    });
  }
}
