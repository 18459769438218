import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import {
  EnsembleService,
  NavigationService,
  ScrollService,
  SearchService,
} from '@jfw-library/ecommerce/core';
import {
  getEnsembleBuyPrice,
  getEnsembleRentPrice,
  getFilterCategoriesByEnsemble,
  getImage,
  getOriginalImage,
  setColorClass,
} from 'business-logic';
import {
  Categories,
  CategoryItem,
  EcomEnsemble,
  FilterCategory,
  Image,
  PaginatorOptions,
  SearchCriteria,
} from 'common-types';
import {
  Observable,
  Subscription,
  catchError,
  firstValueFrom,
  map,
  of,
} from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { trigger } from '@angular/animations';

export interface FilterParam {
  key: string;
  value: string;
}

/// Update 1, 45

@Component({
  selector: 'app-ensemble-listing-page',
  templateUrl: './ensemble.component.html',
  styleUrls: ['./ensemble.component.scss'],
})
export class EnsembleListingPageComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  ensemblesFromAsync: EcomEnsemble[] = [];
  ensemblesFromAsync$: Observable<EcomEnsemble[]> | undefined = undefined;

  ensembles$: Observable<EcomEnsemble[]> | undefined = undefined;
  subscription = new Subscription();
  categories = new Categories();
  categoryItem: CategoryItem | undefined;
  searchText: string | undefined;
  filterCategories: FilterCategory[] | undefined;
  displayItemsOnPage: boolean = true;
  emptyResults = false;

  resultSize = 0;
  pageSize = 24;
  pageIndex = 0;

  filterParams: FilterParam[] = [];

  loadingSearch: boolean = true;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private ensembleService: EnsembleService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private search: SearchService,
    private titleService: Title,
    private metaTagService: Meta,
    private scrollService: ScrollService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe(async (params) => {
      if (params.category) {
        this.categoryItem = this.categories.byRoute(params.category);

        this.loadEnsembles();
      }
    });
  }

  async loadEnsembles() {
    if (this.categoryItem?.category) {
      let categories = getFilterCategoriesByEnsemble();

      this.searchEnsemblesAsync();

      this.filterCategories = await firstValueFrom(
        this.search.getDistinctFiltersByCategories(
          'ecom-ensembles',
          this.categoryItem.category,
          categories,
        ),
      );

      this.ensembleService.filterCategories.next(this.filterCategories);

      if (this.categoryItem) {
        this.setSearchCriteriaFromUrlParams();
        console.log('filterCategories', this.filterCategories);
        // this.subscription.add(
        //   this.ensembleService.searchEnsembles.subscribe({
        //     next: () => this.searchEnsembles(),
        //   })
        // );
        // this.searchEnsembles();
        //this.updateFilterCategories(searchCriteriaArr);
      }
    }

    this.ensembleService.triggerEnsembleSearch.subscribe((trigger) => {
      if (trigger) {
        console.log('triggerEnsembleSearch: ', trigger);
        this.searchEnsemblesAsync();
      }
    });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async searchEnsemblesAsync(paginatorOptions?: PaginatorOptions) {
    this.loadingSearch = true;

    const ensemblesSearchResults = await firstValueFrom(
      this.search.ensemble(this.getSearchCriteria(), paginatorOptions),
    );

    let ensembles: Array<EcomEnsemble> = [];

    const recastedElasticEnsembleType: any = ensemblesSearchResults.hits.total;

    const resultsCount = recastedElasticEnsembleType.value;

    if (resultsCount > 0) {
      // elasticEnsembles.hits.hits.forEach((ensemble) => {
      //   ensembles.push(ensemble._source as EcomEnsemble);
      // });
      ensembles = ensemblesSearchResults.hits.hits.map((ensemble) => {
        return ensemble._source as EcomEnsemble;
      });

      this.emptyResults = false;
      this.resultSize = resultsCount;
    } else {
      this.emptyResults = true;
    }

    if (!paginatorOptions) {
      this.paginator.firstPage();
    }

    this.ensemblesFromAsync = ensembles;

    this.ensemblesFromAsync$ = of(this.ensemblesFromAsync);

    this.scrollToTop();

    this.loadingSearch = false;
  }

  // searchEnsembles() {
  //   this.ensembles$ = this.search
  //     .ensemble(this.getSearchCriteria())
  //     .pipe(
  //       map((elasticEnsembles) => {
  //         let ensembles: Array<EcomEnsemble> = [];
  //         const recastedElasticEnsembleType: any = elasticEnsembles.hits.total;
  //         // To fix later
  //         const resultsCount = recastedElasticEnsembleType.value;
  //         if (resultsCount > 0) {

  //           // elasticEnsembles.hits.hits.forEach((ensemble) => {
  //           //   ensembles.push(ensemble._source as EcomEnsemble);
  //           // });

  //           ensembles = elasticEnsembles.hits.hits.map((ensemble) => {
  //             return ensemble._source as EcomEnsemble;
  //           });

  //           this.emptyResults = false;
  //           this.resultSize = resultsCount;
  //         } else {
  //           this.emptyResults = true;
  //         }
  //         this.paginator.firstPage();

  //         return ensembles;
  //       })
  //     )
  //     .pipe(catchError(this.handleError));
  // }

  filterSelected(event: any) {
    console.log('Base Ensemble Page - Received Filter notification');
    // this.searchEnsembles();
    // this.searchEnsemblesAsync();
  }

  getUrlFilterParams(params: Params) {
    console.log('params');

    let counter: number = 0;

    let filterParam: FilterParam = {} as FilterParam;
    let key: string = '';
    let val: string = '';

    for (const prop in params) {
      if (prop !== 'ensembleGroup' && prop !== 'category') {
        if (prop.startsWith('key')) {
          key = params[prop];
        }

        if (prop.startsWith('val')) {
          val = params[prop];
          let filterParam: FilterParam = {
            key: key,
            value: val,
          };

          this.filterParams.push(filterParam);
        }
      }
    }
  }

  setSearchCriteriaFromUrlParams() {
    if (this.filterParams.length > 0) {
      let searchCriteriaArr: Array<SearchCriteria> = [];

      this.filterParams.forEach((param) => {
        let criteriaValues: string[] = param.value.split('|');

        let searchCriteria: SearchCriteria = {
          attribute: param.key,
          values: criteriaValues,
        };

        searchCriteriaArr.push(searchCriteria);
      });

      this.displayItemsOnPage = true;

      // this.searchEnsemblesFromUrl(searchCriteriaArr);

      this.updateFilterCategories(searchCriteriaArr);
    }
  }

  updateFilterCategories(searchCriteria: SearchCriteria[]) {
    //console.log('did we get here?', this.filterCategories);
    this.filterCategories?.forEach((category) => {
      //console.log('fc', category);
      searchCriteria.forEach((criteria) => {
        if (category.attribute === criteria.attribute) {
          category.options?.forEach((categoryOption: any) => {
            criteria.values.forEach((criteriaOption: any) => {
              if (
                categoryOption.value.toLowerCase() ===
                criteriaOption.toString().toLowerCase()
              ) {
                categoryOption.selected = true;
              }
            });
          });
        }
      });
    });
  }

  setMetaTags(categoryItem: CategoryItem) {
    const baseHeaderTitle: string = environment.baseHeaderTitle;

    this.titleService.setTitle(
      baseHeaderTitle + ' - ' + categoryItem.displayName,
    );
  }

  setColorClass(colorFamily: string, checkbox?: MatCheckbox): string {
    return setColorClass(colorFamily, checkbox);
  }

  // optionClicked() {
  //   this.searchEnsembles();
  // }

  // searchEnsemblesFromUrl(
  //   searchCriteriaArr: SearchCriteria[],
  //   caller: string = 'unknown'
  // ) {
  //   console.log('seFU Caller: ' + caller);
  //   this.ensembles$ = this.search
  //     .ensemble(searchCriteriaArr)
  //     .pipe(
  //       map((elasticEnsembles) => {
  //         let ensembles: Array<EcomEnsemble> = [];
  //         const recastedElasticEnsembleType: any = elasticEnsembles.hits.total;
  //         // To fix later
  //         const resultsCount = recastedElasticEnsembleType.value;
  //         if (resultsCount > 0) {
  //           this.resultSize = resultsCount;
  //           elasticEnsembles.hits.hits.forEach((ensemble) => {
  //             ensembles.push(ensemble._source as EcomEnsemble);
  //           });
  //         }
  //         return ensembles;
  //       })
  //     )
  //     .pipe(catchError(this.handleError));
  // }

  private handleError(err: HttpErrorResponse): Observable<never> {
    console.log('WE ARE HANDLING LISTING-PAGE OBSERVABLE ERROR');
    // console.log(err);
    // return throwError(() => err);
    return new Observable<never>();
  }

  getImage(ensemble: EcomEnsemble): Image {
    let image = getImage(ensemble);
    return image;
  }

  getOriginalImage(ensemble: EcomEnsemble): Image {
    console.log(ensemble.images);
    let image = getOriginalImage(ensemble);
    console.log('original image:');
    console.log(image);
    return image;
  }

  showMobileFilters(): void {
    console.log('show mobile filters');
    this.ensembleService.showFilter.next(true);
    this.navigationService.showPlpNav.next(true);
  }

  getSearchCriteria(): Array<SearchCriteria> {
    let searchCriteria: Array<SearchCriteria> = [];
    if (this.categoryItem?.styleType !== undefined) {
      searchCriteria.push({
        attribute: 'styleType',
        values: [this.categoryItem.styleType],
      });
    }

    this.filterCategories?.forEach((category: FilterCategory) => {
      let criteria: SearchCriteria = {
        attribute: category.attribute,
        values: [],
      };
      let customCriteria: SearchCriteria = {
        attribute: '',
        values: [],
      };
      category.options?.forEach((option: any) => {
        // Rent and Purchase Check
        if (
          // category.attribute === 'ensembleGroup' &&
          option.value === 'Purchase' &&
          option.selected === true
        ) {
          customCriteria.attribute = 'hasPurchaseOption';
          customCriteria.values.push(true);
        }
        if (
          // category.attribute === 'ensembleGroup' &&
          option.selected === true &&
          option.value === 'Rental'
        ) {
          customCriteria.attribute = 'hasPurchaseOption';
          customCriteria.values.push(false);
        }
        // Rent and Purchase Check
        if (option.selected === true && option.value !== 'Purchase') {
          criteria.values.push(option.value);
        }
      });
      if (criteria.values.length > 0) {
        searchCriteria.push(criteria as SearchCriteria);
      }
      if (customCriteria.values.length > 0) {
        searchCriteria.push(customCriteria as SearchCriteria);
      }
    });

    return searchCriteria;
  }

  getRentPrice(ensemble: EcomEnsemble): number {
    return getEnsembleRentPrice(ensemble);
  }

  getBuyPrice(ensemble: EcomEnsemble): number {
    return getEnsembleBuyPrice(ensemble);
  }

  async change(event: PageEvent) {
    let paginatorOptions: PaginatorOptions = {
      previousPageIndex: event.previousPageIndex ?? undefined,
      pageIndex: event.pageIndex,
      pageSize: event.pageSize,
      length: event.length,
    };

    console.log('CHANGE PAGE EVENT');

    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    await this.searchEnsemblesAsync(paginatorOptions);
    // this.ensembles$ = this.search
    //   .ensemble(this.getSearchCriteria(), paginatorOptions)
    //   .pipe(
    //     map((elasticEnsembles) => {
    //       let ensembles: Array<EcomEnsemble> = [];
    //       const recastedElasticEnsembleType: any = elasticEnsembles.hits.total;
    //       // To fix later
    //       const resultsCount = recastedElasticEnsembleType.value;
    //       if (resultsCount > 0) {
    //         this.resultSize = resultsCount;
    //         elasticEnsembles.hits.hits.forEach((ensemble) => {
    //           ensembles.push(ensemble._source as EcomEnsemble);
    //         });
    //       }
    //       return ensembles;
    //     })
    //   )
    //   .pipe(catchError(this.handleError));
    // this.scrollToTop();
  }

  private scrollToTop(): void {
    this.scrollService.scrollToAnchor('listing-anchor', [0, 5000]);
  }
}
