import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Params } from '@angular/router';
import {
  NavigationService,
  ScrollService,
  SearchService,
  StyleService,
} from '@jfw-library/ecommerce/core';
import {
  canOnlyBuyStyle,
  canRentAndBuyStyle,
  getFilterCategoriesByPlpCategory,
  getImage,
  getStyleBuyPrice,
  getStyleRentPrice,
  hasDisplayPrice,
} from 'business-logic';
import {
  Categories,
  Category,
  CategoryItem,
  EcomStyle,
  FilterCategory,
  FilterOption,
  FilterSelect,
  Image,
  PaginatorOptions,
  SearchCriteria,
  StyleGroup,
} from 'common-types';
import { Observable, Subscription, combineLatest, firstValueFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-style-listing-page',
  templateUrl: './style.component.html',
  styleUrls: ['./style.component.scss'],
})
export class StyleListingPageComponent implements OnInit, OnDestroy {
  styles: EcomStyle[] | null = null;
  subscription = new Subscription();
  styleGroup: StyleGroup = StyleGroup.Unassigned;
  categoryItem: CategoryItem | undefined;
  filterCategories: FilterCategory[] = [];
  categories = new Categories();
  resultSize = 0;
  pageSize = 24;
  pageIndex = 0;
  emptyResults = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private styleService: StyleService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private search: SearchService,
    private scrollService: ScrollService,
    private titleService: Title,
    private metaTagService: Meta
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      combineLatest([this.route.params, this.route.queryParams]).subscribe({
        next: ([params, queryParams]) => {
          this.paramsChange(params, queryParams);
          this.getStyles();
        },
      })
    );

    this.subscription.add(
      this.styleService.searchStyles.subscribe({
        next: () => this.searchStyles(),
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private async paramsChange(
    params: Params,
    queryParams: Params
  ): Promise<void> {
    // switch (params.styleGroup) {
    //   case 'rental':
    //     this.styleGroup = StyleGroup.Rental;
    //     break;
    //   case 'purchase':
    //     this.styleGroup = StyleGroup.Purchase;
    //     break;
    //   default:
    //     this.styleGroup = StyleGroup.Unassigned;
    // }

    this.styleGroup = StyleGroup.Rental;

    if (params.category) {
      this.categoryItem = this.categories.byRoute(params.category);
      if (this.categoryItem) {
        this.setMetaTags(this.categoryItem);
      }
      if (this.categoryItem?.category === 'ShoesAndSocks') {
        // TO BE REMOVED WHEN SHOES AND SOCKS MERGED
        let colorFamily: FilterCategory = {
          attribute: 'colorFamily',
          displayName: 'Color Family',
          options: [],
        };
        let product: FilterCategory = {
          attribute: 'styleType',
          displayName: 'Product',
          options: [],
        };

        let styleGroup: FilterCategory = {
          attribute: 'styleGroup',
          displayName: 'Rent or Buy',
          options: [],
        };

        let styleCategories = getFilterCategoriesByPlpCategory(
          this.categoryItem?.category
        );

        this.search
          .getDistinctFiltersByCategories(
            'ecom-styles',
            Category.Shoes,
            styleCategories
          )
          .subscribe((filters) => {
            filters.forEach((filter) => {
              if (filter.attribute === 'colorFamily') {
                if (colorFamily && colorFamily.options && filter.options) {
                  colorFamily.options = [
                    ...colorFamily.options,
                    ...filter.options,
                  ];
                  colorFamily.options = [
                    ...new Map(
                      colorFamily.options.map((option: FilterOption) => [
                        option['value'],
                        option,
                      ])
                    ).values(),
                  ];
                }
              }
              if (filter.attribute === 'styleGroup') {
                if (styleGroup.options && filter.options) {
                  styleGroup.options = [
                    ...styleGroup.options,
                    ...filter.options,
                  ];
                  styleGroup.options = [
                    ...new Map(
                      styleGroup.options.map((option: FilterOption) => [
                        option['value'],
                        option,
                      ])
                    ).values(),
                  ];
                  this.filterCategories = [colorFamily, styleGroup, product];
                  this.styleService.filterCategories.next([
                    colorFamily,
                    styleGroup,
                    product,
                  ]);
                }
              }
              if (filter.attribute === 'styleType') {
                if (product && product.options && filter.options) {
                  product.options = [...product.options, ...filter.options];
                  product.options = [
                    ...new Map(
                      product.options.map((option: FilterOption) => [
                        option['value'],
                        option,
                      ])
                    ).values(),
                  ];
                  this.filterCategories = [colorFamily, product];
                  this.styleService.filterCategories.next([
                    colorFamily,
                    product,
                  ]);
                }
              }
            });
          });

        this.search
          .getDistinctFiltersByCategories(
            'ecom-styles',
            Category.Socks,
            styleCategories
          )
          .subscribe((filters) => {
            filters.forEach((filter) => {
              if (filter.attribute === 'colorFamily') {
                if (colorFamily.options && filter.options) {
                  colorFamily.options = [
                    ...colorFamily.options,
                    ...filter.options,
                  ];
                  colorFamily.options = [
                    ...new Map(
                      colorFamily.options.map((option: FilterOption) => [
                        option['value'],
                        option,
                      ])
                    ).values(),
                  ];
                }
              }
              if (filter.attribute === 'styleType') {
                if (product.options && filter.options) {
                  product.options = [...product.options, ...filter.options];
                  product.options = [
                    ...new Map(
                      product.options.map((option: FilterOption) => [
                        option['value'],
                        option,
                      ])
                    ).values(),
                  ];
                }
              }
              if (filter.attribute === 'styleGroup') {
                if (styleGroup.options && filter.options) {
                  styleGroup.options = [
                    ...styleGroup.options,
                    ...filter.options,
                  ];
                  styleGroup.options = [
                    ...new Map(
                      styleGroup.options.map((option: FilterOption) => [
                        option['value'],
                        option,
                      ])
                    ).values(),
                  ];
                  this.filterCategories = [colorFamily, styleGroup, product];
                  this.styleService.filterCategories.next([
                    colorFamily,
                    styleGroup,
                    product,
                  ]);
                }
              }
            });
          });
      } else if (this.categoryItem?.category) {
        let styleCategories = getFilterCategoriesByPlpCategory(
          this.categoryItem?.category
        );
        this.search
          .getDistinctFiltersByCategories(
            'ecom-styles',
            this.categoryItem?.category,
            styleCategories
          )
          .subscribe((filters) => {
            this.filterCategories = filters;
            this.styleService.filterCategories.next(this.filterCategories);
          });
      }
    }
  }

  setMetaTags(categoryItem: CategoryItem) {
    const baseHeaderTitle: string = environment.baseHeaderTitle;

    this.titleService.setTitle(
      baseHeaderTitle + ' - ' + categoryItem.displayName
    );
  }

  private async getStyles() {
    try {
      this.styles = null;
      this.filterCategories = [];
      if (this.styleGroup !== StyleGroup.Unassigned && this.categoryItem) {
        await this.searchStyles();
      }
    } catch (error: any) {
      console.log(
        'An error occurred on getStyles() > listing-page.component.ts'
      );
    }
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    console.log('WE ARE HANDLING LISTING-PAGE OBSERVABLE ERROR');
    // console.log(err);
    // return throwError(() => err);
    return new Observable<never>();
  }

  private async searchStyles() {
    this.styles = await firstValueFrom(
      this.search
        .style(this.getSearchCriteria())
        .pipe(
          map((elasticStyles) => {
            const styles: EcomStyle[] = [];
            const recastedElasticStylesType: any = elasticStyles.hits.total;
            // To fix later
            const resultsCount = recastedElasticStylesType.value;
            if (elasticStyles.hits.hits.length > 0) {
              this.emptyResults = false;
              this.resultSize = resultsCount;
              elasticStyles.hits.hits.forEach((style: any) => {
                styles.push(style._source as EcomStyle);
              });
            } else {
              this.emptyResults = true;
            }
            return styles;
          })
        )
        .pipe(catchError(this.handleError))
    );
    if (this.paginator && !this.emptyResults) {
      this.paginator.firstPage();
    }
  }

  private getSearchCriteria(): SearchCriteria[] {
    const searchCriteria: SearchCriteria[] = [];

    if (this.categoryItem) {
      if (this.categoryItem.category === Category.ShoesAndSocks) {
        // rental shoe override - will remove when
        // purchase-only product is enabled on ecom
        searchCriteria.push({
          attribute: 'categories',
          values: [Category.Shoes, Category.Socks],
        });
      } else {
        searchCriteria.push({
          attribute: 'categories',
          values: [this.categoryItem.category],
        });

        // accessories override - include both rental and purchase
        // will remove if statement when purchase-only product is enabled on ecom
        // future state: search by category and styleGroup - no overrides
        if (this.categoryItem.category !== Category.Accessories) {
          searchCriteria.push({
            attribute: 'styleGroup',
            values: [this.styleGroup],
          });
        }
      }
    }

    this.filterCategories.forEach((category) => {
      const criteria: SearchCriteria = {
        attribute: category.attribute,
        values: [],
      };
      category.options?.forEach((option) => {
        if (option.selected) {
          criteria.values.push(option.value);
        }
      });
      if (criteria.values.length > 0) {
        searchCriteria.push(criteria as SearchCriteria);
      }
    });
    return searchCriteria;
  }

  public get filterSelect(): typeof FilterSelect {
    return FilterSelect;
  }

  public getImage(style: EcomStyle): Image {
    return getImage(style);
  }

  public showMobileFilters(): void {
    this.styleService.showFilter.next(true);
    this.navigationService.showPlpNav.next(true);
  }

  public getRentPrice(style: EcomStyle): number {
    return getStyleRentPrice(style) ?? 0;
  }

  public getBuyPrice(style: EcomStyle): number {
    return getStyleBuyPrice(style) ?? 0;
  }

  public hasDisplayPrice(ecomStyle: EcomStyle): boolean {
    return hasDisplayPrice(ecomStyle);
  }

  public isPurchaseOnly(ecomStyle: EcomStyle): boolean {
    return canOnlyBuyStyle(ecomStyle);
  }

  public displayRentText(ecomStyle: EcomStyle): string {
    if (canRentAndBuyStyle(ecomStyle)) {
      return 'AVAILABLE TO RENT OR BUY';
    } else {
      return 'AVAILABLE TO RENT';
    }
  }

  async change(event: PageEvent) {
    let paginatorOptions: PaginatorOptions = {
      previousPageIndex: event.previousPageIndex ?? undefined,
      pageIndex: event.pageIndex,
      pageSize: event.pageSize,
      length: event.length,
    };
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.styles = await firstValueFrom(
      this.search
        .style(this.getSearchCriteria(), paginatorOptions)
        .pipe(
          map((elasticStyles) => {
            const styles: EcomStyle[] = [];
            const recastedElasticStylesType: any = elasticStyles.hits.total;
            // To fix later
            const resultsCount = recastedElasticStylesType.value;
            if (elasticStyles.hits.hits.length > 0) {
              this.resultSize = resultsCount;
              elasticStyles.hits.hits.forEach((style: any) => {
                styles.push(style._source as EcomStyle);
              });
            }
            return styles;
          })
        )
        .pipe(catchError(this.handleError))
    );
    this.scrollToTop();
  }

  private scrollToTop(): void {
    this.scrollService.scrollToAnchor('listing-anchor', [0, 5000]);
  }
}
