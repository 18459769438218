<div
  style="max-width: 300px; max-height: 300px; margin: auto; text-align: center"
>
  <div *ngIf="!prodModeRestricted">Disabled</div>
  <div *ngIf="prodModeRestricted && prodModeNormal">Open</div>
  <div *ngIf="prodModeRestricted && !prodModeNormal">
    <p style="color: red" *ngIf="invalidPassword">
      <strong>Invalid Password</strong>
    </p>
    <form (ngSubmit)="submitForm()">
      <div>
        <mat-form-field>
          <mat-label>Password</mat-label>
          <input
            matInput
            name="password"
            id="password"
            type="password"
            [(ngModel)]="password"
            required
          />
        </mat-form-field>

        <!-- <label for="name">Name:</label>
      <input type="text" id="password" name="password" [(ngModel)]="password" /> -->
      </div>
      <div style="display: flex; justify-content: center" *ngIf="showSpinner">
        <mat-spinner diameter="40"></mat-spinner>
      </div>

      <button mat-raised-button type="submit" *ngIf="!showSpinner">
        Enter
      </button>
    </form>
  </div>
</div>
