@if (servedEnvironment === "local") {
  <div class="local-site">Local</div>
} @else if (servedEnvironment === "default") {
  <div class="dev-site">Dev</div>
} @else if (servedEnvironment === "test") {
  <div class="test-site">Test</div>
}

<button
  *ngIf="showPanic"
  mat-flat-button
  color="warn"
  class="panic-button"
  (click)="panicEventDocListener()"
  [disabled]="disablePanic || !eventDocListenerActive"
  [matBadge]="numEventDocUpdates$ | async" matBadgePosition="below after" matBadgeColor="accent" [matBadgeHidden]="(numEventDocUpdates$ | async) === 0"
  >
    @if(disablePanic) {
      'Sync: Disabled'
    }
    @else if(!eventDocListenerActive) {
      'Sync: Inactive'
    }
    @else {
      'Stop Sync'
    }
  </button>

  <div class="desktop">
  <app-header-desktop
    [user]="user"
    [isAnon]="isAnon"
    (anonEvent)="onAnon()"
    (getStartedEvent)="onGetStarted()"
  ></app-header-desktop>
</div>

<div class="mobile">
  <app-header-mobile
    [user]="user"
    [isAnon]="isAnon"
    (sidenavEvent)="onSidenav()"
    (searchEvent)="onSearch()"
    (anonEvent)="onAnon()"
  ></app-header-mobile>
</div>
