import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  EnsembleService,
  NavigationService,
  StyleService,
} from '@jfw-library/ecommerce/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-customize-look-filters',
  templateUrl: './customize-look.component.html',
  styleUrls: ['./customize-look.component.scss'],
})
export class CustomizeLookFiltersComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  disableAnimation = true;
  showStylesFilters = false;
  showEnsembleFilters = false;
  subscription = new Subscription();

  constructor(
    private navigationService: NavigationService,
    private styleService: StyleService,
    private ensembleService: EnsembleService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.styleService.showFilter.subscribe((value: boolean) => {
        this.showStylesFilters = value;
      })
    );

    this.subscription.add(
      this.ensembleService.showFilter.subscribe((value: boolean) => {
        this.showEnsembleFilters = value;
      })
    );
  }

  ngAfterViewInit(): void {
    // workaround for angular component issue #13870
    setTimeout(() => (this.disableAnimation = false));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  close(): void {
    this.styleService.showFilter.next(false);
    this.ensembleService.showFilter.next(false);
    this.navigationService.showPlpNav.next(false);
  }
}
